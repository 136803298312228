// @flow
import React from 'react'
import { Link, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { Container } from 'reactstrap'

import { Button } from '../components/UI'
import Layout, { SEO } from '../components/Layout'

import mapAirtableData from '../lib/mapAirtableData'

type Props = {
  data: Object,
}

const Box = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: center;
`

const IndexPage = (props: Props) => {
  // useEffect(() => {
  //   navigate('/willkommen')
  // })

  const {
    data: { allAirtable },
  } = props
  const texts = mapAirtableData(allAirtable)

  return (
    <Layout>
      <SEO title={texts['Überschrift 1']} />

      <Container>
        <h1>{texts['Überschrift 1']}</h1>

        <ReactMarkdown source={texts.Einleitungstext} />

        <Box>
          <Link to="/app/verfassen">
            <Button as="span">{texts['Call To Action Label']}</Button>
          </Link>
        </Box>
      </Container>
      {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "Übersicht" } }) {
      edges {
        node {
          recordId
          data {
            Name
            Text
          }
        }
      }
    }
  }
`
